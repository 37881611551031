<template>
  <div class="form-content">
    <el-form ref="form" :model="form">
      <el-form-item :label="$t('Categorie Name (Chinese)')" style="max-width: 80%">
        <el-input v-model="form.categorieNameZh"></el-input>
      </el-form-item>
      <el-form-item :label="$t('Categorie Name (English)')" style="max-width: 80%">
        <el-input v-model="form.categorieNameEn"></el-input>
      </el-form-item>
    </el-form>
    <div class="submit-wrapper">
      <el-button class="submit-button" type="primary" @click="submit">{{ $t('Submit') }}</el-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'CategorieForm',
  methods:{
    submit(){
      this.$emit('action', this.form);
    },
  },
  data(){
    const form = {
      categorieNameZh: '',
      categorieNameEn: '',
    };
    return {
      form,
    }
  },
  watch: {
    'currentCategorie': function(){
      this.form.categorieNameZh = this.currentCategorie.categorie_name_zh;
      this.form.categorieNameEn = this.currentCategorie.categorie_name_en;
    }
  },
  computed: mapState({
    apiUrl: state => state.apiUrl,
    currentCategorie: state => state.menu.currentCategorie,
  }),
}
</script>

<style lang="scss">
.form-content{
  padding-left: 20px;
  .el-form-item__label{
    float: none;
  }
  .update-button{
    .el-form-item__content{
      text-align: right;
    }
  }
  .submit-wrapper{
    text-align: right;
  }
}
</style>
