<template>
  <div class="categorie">
    <div class="page-header">
      <h1>{{ $t('Menu Management') }} > {{ $t('Categories') }} > {{ $t('Edit') }}</h1>
    </div>
    <div class="page-content">
      <CategorieForm @action="updateCategorie" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import CategorieForm from '@/components/menu/CategorieForm.vue';
import Common from '@/lib/common';
import Menu from '@/lib/menu';

export default {
  name: 'EditCategorie',
  components: {
    CategorieForm,
  },
  mounted(){
    this.loadCategorie();
  },
  methods:{
    async loadCategorie(){
      try{
        const loginInfo = Common.getLoginInfo();
        const categorieId = this.$route.params.id;
        const categorie = await Menu.loadCategorie(this.apiUrl, categorieId, loginInfo);
        this.setCurrentCategorie(categorie);
      }catch(err){
        console.log(err);
      }
    },
    async updateCategorie(form){
      try{
        const loginInfo = Common.getLoginInfo();
        const categorieId = this.$route.params.id;
        const categorie = await Menu.updateCategorie(this.apiUrl, categorieId, form, loginInfo);
        this.$router.push('/categories');
      }catch(err){
        console.log(err);
      }
    },
    ...mapActions(['setCurrentCategorie']),
  },
  data(){
    const form = {
      categorieNameZh: '',
      categorieNameEn: '',
    };
    return {
      form,
    }
  },
  computed: mapState({
    apiUrl: state => state.apiUrl,
  }),
}
</script>

<style lang="scss">
.content-header{
  display: flex;
  h2{
    flex: 1;
  }
  .action-button{
    margin-right: 20px;
  }
}
.submit-wrapper{
  text-align: right;
}
</style>
